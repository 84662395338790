import { View, Text, Image } from 'react-native';
import styles from './Header.module.css';
import {TouchableOpacity} from "react-native-web";


function Header() {
    return (
        <View id="header">
            <View id="left">
                <Image
                    source={require('../../assets/logo.png')}
                    style={{ width: 116, height: 45 }}
                />
            </View>
            <View id="right">
                <TouchableOpacity id="rightSignIn">
                    <Text>OTURUM AÇIN</Text>
                </TouchableOpacity>
                <TouchableOpacity id="rightYeriniziAlin">
                    <Text>YERİNİZİ ALIN</Text>
                </TouchableOpacity>
                <Text id="rightText">Yeni Nesil Televizyonculuk</Text>

            </View>
        </View>
    );
}

export default Header;
