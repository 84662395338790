import { Text, View } from 'react-native';
import styles from './App.module.css';
import Header from './web/includes/Header';

export default function App() {
  return (
    <View id="App" className={styles.App}>
      <Header />

    </View>
  );
}


